<template>
  <el-dialog :visible.sync="dialogVisible" custom-class="contact-dialog" @close="closeDialog">
    <div class="contact-content">
      <div class="get-in-touch">
        <p class="title">Get In Touch</p>
        <div class="email-box">
          <p class="email">
            <i class="icon-email fs16"></i>
            PDM@tencent.com
          </p>
          <p class="email">
            <i class="icon-email fs16"></i>
            info@pdm-ai.com
          </p>
        </div>
        <img src="../../assets/home/logo-all-white.png">
      </div>

      <div class="contact-form">
        <img
          class="close-btn"
          src="../../assets/home/close.png"
          @click="closeDialog"
        >

        <el-input v-model="formData.name" placeholder="Name"></el-input>
        <el-input v-model="formData.company" placeholder="Company"></el-input>
        <el-input v-model="formData.email" type="email" placeholder="Email Address"></el-input>
        <el-input v-model="formData.message" placeholder="Massage" type="textarea" rows="4" resize="none"></el-input>
        <el-button @click="sendInfo">Send</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      dialogVisible: false,
      formData: {
        name: '',
        company: '',
        email: '',
        message: ''
      }
    }
  },

  watch: {
    visible (val) {
      this.dialogVisible = val
    }
  },

  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    },

    async sendInfo () {
      let mail = /([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+/
      const formData = this.formData
      if (!formData.name) {
        this.$message({
          message: this.$t('h.home.nameMsg'),
          type: "warning"
        })
      } else if (!formData.company) {
        this.$message({
          message: this.$t('h.home.companyMsg'),
          type: "warning"
        })
      } else if (!formData.email) {
        this.$message({
          message: this.$t('h.home.emailMsg'),
          type: "warning"
        })
      } else if (!mail.test(formData.email)) {
        this.$message({
          message: this.$t('h.home.emailFormatMsg'),
          type: "warning"
        })
      } else {
        const result = await this.$store.dispatch('sendInfo', this.formData)
        if (result.success) {
          this.$message({
            message: this.$t('h.home.concactLater'),
            type: "success"
          })
          this.$emit('closeDialog')
          this.initForm()
        } else {
          this.$message({
            message: this.$t('h.home.tryAgain'),
            type: "warning"
          })
          this.initForm()
        }
      }
    },

    initForm () {
      this.formData = {
        name: '',
        company: '',
        email: '',
        message: ''
      }
    }
  }
}
</script>

<style lang="stylus">
.contact-dialog {
  width: 600px;
  border-radius: 10px !important;

  .el-dialog__header {
    padding: 0;

    .el-dialog__headerbtn {
      display: none;  
    }
  }

  .el-dialog__body {
    padding: 0;
  }

  .contact-content {
    width: 100%;
    height: 400px;
    display: flex;
  }

  .get-in-touch {
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px; 
    background: #FF8C36;
    width: 30%;
    color: #fff;
    padding: 50px 40px 40px;
    display: flex;
    flex-direction: column;

    .title {
      font-family: 'NeoTech Black';
      font-size: 26px;
      margin-bottom: 20px;
    }

    .email-box {
      user-select: text;
      flex: 1;
    }

    .email {
      line-height: 26px;
    }

    img {
      width: 90%;
      align-content: flex-end;
    }
  }

  .contact-form {
    flex: 1;
    position: relative;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .close-btn {
      position: absolute;
      right: 0;
      top: 0;
      width: 25px;
      cursor: pointer;
    }

    .el-input__inner, .el-textarea__inner {
      border: none;
      background: #f5f5f5;
    }

    .el-button {
      background: #E46B4F;
      color: #fff;
    }
  }
}
</style>