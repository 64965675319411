<template>
<!-- FF8C36  -->
  <div id="home" class="home-page">
    <div class="home-header">
      <img class="pdm-logo" src="../../assets/home/logo-white.png">
      <div v-if="!isLogin" class="login-btn" @click="showLogin=true">
        <i class="icon-user fs16 mr5"></i>
        <span>Login</span>  
      </div>
      <div v-else class="login-btn" @click="$router.push('/task-list')">
        <span>Start</span>  
      </div>
    </div>

    <div class="main-text">
      <div class="slogan-box">
        <div class="flex-box">
          <div class="four-text gradient-text" @mouseenter="mouseEnter('spot')">
            <p class="init-text">SPOT</p>
            <p id="spot" class="change-text">SPOT</p>
          </div>
          <div class="gradient-text two-text" @mouseenter="mouseEnter('on')">
            <p class="init-text">ON</p>
            <p id="on" class="change-text">ON</p>
          </div>
          <div class="gradient-text four-text" @mouseenter="mouseEnter('next')">
            <p class="init-text">NEXT</p>
            <p id="next" class="change-text">NEXT</p>
          </div>
        </div>
        <div class="flex-box">
          <div class="gradient-text five-text" @mouseenter="mouseEnter('music')">
            <p class="init-text">MUSIC</p>
            <p id="music" class="change-text">MUSIC</p>
          </div>
          <div class="gradient-text six-text" @mouseenter="mouseEnter('trends')">
            <p class="init-text">TRENDS<span class="normal-text">.</span></p>
            <p id="trends" class="change-text">TRENDS<span class="normal-text">.</span></p>
          </div>
        </div>
      </div>

      <div class="icon-box">
        <img class="music-pic" src="../../assets/home/pink-play.png">
        <img class="plus-icon" src="../../assets/home/icon-plus.png">
        <img class="music-pic" src="../../assets/home/blue-album.png">
        <img class="plus-icon" src="../../assets/home/icon-plus.png">
        <img class="music-pic" src="../../assets/home/orange-music.png">
      </div>

      <div class="sub-text">
        <span style="color:#ff8c36;">PDM (Predictive Model)</span> realizes full potential of every music <br> with global exclusive AI prediction technologies.
      </div>
    </div>

    <div class="tech-list">
      <div class="tech-item" v-for="(tech, index) in techList" :key="tech.name">
        <img :src="require(`../../assets/home/${tech.icon}.png`)">
        <div class="tech-text">
          <div class="tech-title">
            <p class="tech-no">{{ '0' + (index+1) }}</p>
            <p class="tech-name">{{ tech.name }}</p>
          </div>
          <div class="tech-desc">{{ tech.desc }}</div>
        </div>
      </div>
    </div>

    <div id="solution" class="solution-box">
      <div class="dsp-box">
        <div @click="showFolder(1)" class="solution-header">
          <p class="solution-title">For Music Streaming Services</p>
          <div class="more-btn" @click="contactUs">LEARN MORE</div>
        </div>
        <div class="solution-desc">
          PDM provides intelligent solutions for music streaming services to enable an <br>
          automatic up-coming releases pitching and playlist curating process.
        </div>
        <div class="dsp-content">
          <div class="current-scene">
            <div class="scene-title">
              <p>ESTIMATION</p>
              <p>UNDER</p>
              <p>CURRENT</p>
              <p>PRATICE</p>
            </div>
            <div class="per-day">
              <div class="editor-num">
                <span class="num-60">60+</span>
                <span class="unit-text">Editors</span>
              </div>
              <p class="scene-desc">Human input for evaluating all new releases per day</p>
            </div>
            <div class="per-week">
              <div class="num-1000">1000-2000</div>
              <p class="scene-desc">Up-coming releases treated in general by random DSP per week</p>
            </div>
          </div>
          <div class="with-pdm">
            <div class="pdm-scene">
              <p class="with-text">WITH</p>
              <p class="pdm-text">PDM</p>
            </div>
            <div class="num-box">
              <img class="star-icon left-star" src="../../assets/home/icon-star.png">
              <p class="num-0">0</p>
              <img class="star-icon right-star" src="../../assets/home/icon-star.png">
            </div>
            <div class="pdm-desc">
              <p>Support</p>
              <p>Unlimited</p>
            </div>
          </div>
        </div>
      </div>

      <div 
        id="distributor" 
        class="distributors-box"
      > 
        <div @click="showFolder(2)" class="solution-header">
          <p class="solution-title">For Content Distributors</p>
          <div class="more-btn" @click="contactUs">LEARN MORE</div>
        </div>
        <div class="distributor-sub">
          <p class="solution-desc">PDM provides comprehensive and exlusive AI promotion features to serve artists and labels, <br>empowering the promotional services for distributors and aggregators.</p>
          <i id="nextBtn" class="icon-next next-btn" @click="clickNext"></i>
        </div>
        <div id="reportDiv" class="pdm-report">
          <img src="../../assets/home/report1.png">
          <img src="../../assets/home/report2.png">
        </div>
      </div>
    </div>

    <div id="highlight" class="high-light">
      <div class="module-box">
        <div class="discovery-box">
          <p class="module-name">Discovery</p>
          <p class="module-desc">PDM (Predictive Model) has been presented by Discovery Channel in the documentary <i>China by Numbers 2022</i>.</p>
        </div>
        <div class="video-div">
          <video 
            :controls="videoControl"
            id="discovery"
            class="video-box"
            preload="auto"
            poster="../../assets/home/screenshot.png"
            src="https://tme-chuangxin-hw-1258344705.cos.ap-hongkong.myqcloud.com/report/pdm-site/discovery%20X%20PDM.mp4"
          />
          <img class="play-btn" src="../../assets/home/play.png" @click="playDiscoveryVideo">
        </div>
      </div>

      <div class="module-box mirex-box">
        <img class="mirex-logo" src="../../assets/home/mirex_logo.png">
        <div class="mirex-text">
          <p class="module-name">MIREX</p>
          <p class="module-desc">In the 2020 MIREX Patterns For Prediction sub-project competition, PDM technology broke the world record by predicting the melody quality of songs in terms of audio content, melody, rhythm and other dimensions.</p>
        </div>
      </div>
    </div>

    <div class="gray-bg">
      <div class="gray-box mb-7vw">
        <p class="module-title">TRUSTED BY</p>
        <div class="company-box">
          <img src="../../assets/home/afrotunes.png">
          <img src="../../assets/home/hikoon.png">
          <img src="../../assets/home/kanjian.png">
        </div>
        <div class="company-box">
          <img src="../../assets/home/qqmusic.png">
          <img src="../../assets/home/kugou.png">
          <img src="../../assets/home/tencent-musician.png">
        </div>
      </div>

      <div class="gray-box">
        <p class="module-title">ABOUT US</p>
        <div class="team-brief">
          The PDM (Predictive Model) solution is an AI-powered services exclusively developed by the Lyra Lab of TME (Tencent Music Entertainment Group) for labels, content providers and DSPs. PDM provides AI insights and predictions in various aspects for recognizing high-potential songs and assisting decision-making in music manufacturing and promotion processes.
        </div>
        <div class="team-brief">
          TME's Lyra Lab is committed to bringing new growth and evolution to the music entertainment industry through technology breakthroughs. In addition to PDM technologies, we have also developed world-leading cross-modality music matching technology, MMatch (Multimodal Match). MMatch has brought significant values to various business, such as in-store music playlist customization, content operation and short video production.
        </div>
        <div class="contact-btn" @click="contactUs">
          GET IN TOUCH
        </div>
      </div>
    </div>

    <div class="home-footer">
      <div class="footer-box">
        <div>
          <img class="logo-img" src="../../assets/home/logo-black.png">
          <p class="copy-right">Tencent Music Entertainment Group. All Rights Reserved.</p>
        </div>
        <div>
          <a href="https://www.tencentmusic.com/" target="_blank">
            <img class="footer-link" src="../../assets/home/tme.png">
          </a>
          <a href="https://mmatch.tencentmusic.com/#/" target="_blank">
            <img class="footer-link" src="../../assets/home/mmatch.png">
          </a>
        </div>
      </div>
    </div>

    <concact-dialog :visible="showConcact" @closeDialog="closeContactDialog"></concact-dialog>
    <login-dialog :visible="showLogin" @closeDialog="closeLoginDialog"></login-dialog>
  </div>
</template>

<script>
import ConcactDialog from './__contact-dialog.vue'
import LoginDialog from './__login-dialog.vue'

export default {
  components: {
    ConcactDialog,
    LoginDialog
  },

  data () {
    return {
      solutionEl: '',
      highlightEl: '',
      distributorEl: '',
      innerHeight: 0,
      pageHeight: 0,
      isNext: false,
      videoControl: false,
      techList: [{
        name: 'Tracks Ratings / Assessment',
        icon: 'ai',
        desc: 'Provide AI insights and predictions on market potential for music tracks based on AUDIO and LYRICS only. ',
      },{
        name: 'Playlist Analysis',
        icon: 'playlist',
        desc: 'Reveal the most suitable playlists options from various music streaming platforms for new releases pitching.',
      },{
        name: 'Audience Profile Prediction',
        icon: 'audience',
        desc: 'Predict target audience based on AI analysis of artist data, music content (audio&lyrics) and track-down of changing music trends around the world.',
      },{
        name: 'Short-video Promotion Analysis',
        icon: 'video',
        desc: 'Get inspired about how to make up-coming release go viral from a simulation of trending videos curated with your music',
      }],
      showConcact: false,
      showLogin: false,
      flag: true,
      timer: null,
      isMac: false,
      stayAnimation: false,
      heightIndex: 0,
      deltaY: undefined,
      isDSP: true,
      stopSolution: false,
      isDiscovery: true,
      stopHighlight: false,
      stopScroll: false,
      isWaiting: false
    }
  },

  computed: {
    isLogin() {
      return !!this.$store.state.user.token
    }
  },

  mounted () {
    localStorage.setItem("lang", 'en')
    this.$i18n.locale = localStorage.getItem("lang")
    this.mouseEnter('trends')
    this.isMac = /macintosh|mac os x/i.test(navigator.userAgent)
    this.$nextTick(() => {
      this.initData()
    })
    window.onresize = () => {
      setTimeout(() => {
        this.initData()
      })
    }
    document.getElementById('home').addEventListener('wheel', this.debounce, false)

  },

  methods: {
    initData () {
      this.innerHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      this.pageHeight = document.getElementById('home').scrollHeight
      this.solutionEl = document.getElementById('solution')
      this.highlightEl = document.getElementById('highlight')
      this.distributorEl = document.getElementById('distributor')
      this.deltaY = this.innerHeight / 8
    },

    debounce (ev) {
      clearTimeout(this.timer)
      this.timer = setTimeout(this.scrollFn(ev), this.isMac ? 150 : 0)
    },

    scrollFn (e) {
      e.preventDefault()
      if (!this.flag) return
      this.flag = false

      // 滑动方向
      const event = e || window.event
      const down = event.wheelDelta ? event.wheelDelta < 0 : event.detail > 0

      let start = 0
      const step = (unix) => {
        if (!start) {
          start = unix
        }
        const duration = unix - start
        // 鼠标滚动一次页面滑动1s, 每秒60帧
        if (duration <= 1000) {
          const solutionTop = this.solutionEl.getBoundingClientRect().top
          const highlightTop = this.highlightEl.getBoundingClientRect().bottom - this.innerHeight
          const parent = document.getElementById('home')
          if (this.isWaiting) {
            return
          } else if (Math.abs(solutionTop) <= 1 && ((down && this.isDSP) || (!down && !this.isDSP))) {
            this.stopSolution = true
            this.handleSolutionScroll(down)
            window.cancelAnimationFrame(step)
            return
          } else if (Math.abs(highlightTop) <= 1 && ((down && this.isDiscovery) || (!down && !this.isDiscovery))) {
            this.stopHighlight = true
            this.handleHighLightScroll(down)
            window.cancelAnimationFrame(step)
            return
          } else {
            if (this.stopSolution === true) {
              this.stopSolution = false
              return
            }
            if (this.stopHighlight === true) {
              this.stopHighlight = false
              return
            }
            const correctOption = (solutionTop >= 0 && solutionTop <= 30 && down && this.isDSP) || (solutionTop <= 0 && solutionTop >= -30 && !down && !this.isDSP)
            if (correctOption) {
              this.isWaiting = true
              setTimeout(() => {
                this.isWaiting = false
              }, 100)
              parent.scrollTop = this.solutionEl.offsetTop
              this.heightIndex = Math.floor(this.solutionEl.offsetTop / (this.deltaY / (this.isMac ? 25 : 75)))
              window.cancelAnimationFrame(step)
              return
            }
            const correctOption2 = (highlightTop >= 0 && highlightTop <= 30 && down && this.isDiscovery) || (highlightTop <= 0 && highlightTop >= -30 && !down && !this.isDiscovery)
            if (correctOption2) {
              this.isWaiting = true
              setTimeout(() => {
                this.isWaiting = false
              }, 100)
              parent.scrollTop = (this.highlightEl.offsetTop + this.highlightEl.clientHeight - this.innerHeight )
              this.heightIndex = Math.floor((this.highlightEl.offsetTop + this.highlightEl.clientHeight - this.innerHeight)  / (this.deltaY / (this.isMac ? 25 : 75)))
              window.cancelAnimationFrame(step)
              return
            }
            if (this.stopScroll) return
            parent.scrollTop = this.heightIndex * (this.deltaY / (this.isMac ? 25 : 75))
            const overHeight = this.pageHeight - this.innerHeight - parent.scrollTop
            if (down && overHeight >= 1) {
              this.heightIndex++
            } 
            if (!down && this.heightIndex > 0) {
              this.heightIndex--
            }
          }
          window.requestAnimationFrame(step)
        }
      }
      window.requestAnimationFrame(step)
      this.changeFlag()
    },

    changeFlag () {
      setTimeout(() => {
        this.flag = true
      }, this.isMac ? 150 : 0)
    },

     handleSolutionScroll (down) {
      this.distributorEl.style.transition = `all 0.5s ease 0s`
      if (down) {
        this.isDSP = false
        this.distributorEl.style.transform = `translateY(11vh)`
      } else {
        this.isDSP = true
        this.distributorEl.style.transform = `translateY(89vh)`
      }
      this.stopScroll = true
      setTimeout(() => {
        this.stopScroll = false
      }, 400)
    },

    handleHighLightScroll (down) {
      this.highlightEl.style.transition = `all 0.5s ease 0s`
      if (down) {
        this.isDiscovery = false
        this.highlightEl.style.transform = `translate3d(-48vw, 0, 0)`
      } else {
        this.isDiscovery = true
        this.highlightEl.style.transform = `translate3d(5vw, 0, 0)`
      }
      this.stopScroll = true
      setTimeout(() => {
        this.stopScroll = false
      }, 400)
    },

    showFolder (type) {
      if (type === 2) {
        this.handleSolutionScroll(true)
      } 
      if (type === 1) {
        this.handleSolutionScroll(false)
      }
    },

    mouseEnter (id) {
      const changTextList = document.getElementsByClassName('change-text')
      changTextList.forEach(item => {
        item.style.opacity = 1
        item.style.background = '#000'
      })
      const el = document.getElementById(id)
      el.style.opacity = 0
      el.style.background = 'rgba(0,0,0,0)'
    },

    clickNext () {
      this.isNext = !this.isNext
      const reportDiv = document.getElementById('reportDiv')
      let reportClass = reportDiv.getAttribute("class").replace(' next-report','').replace(' last-report','')
      const nextBtn = document.getElementById('nextBtn')
      let btnClass = nextBtn.getAttribute("class")
      if (this.isNext) {
        btnClass = btnClass.concat(' is-last')
        reportClass = reportClass.concat(' last-report')
      } else {
        btnClass = btnClass.replace(' is-last','')
        reportClass = reportClass.concat(' next-report')
      }
      nextBtn.setAttribute("class", btnClass)
      reportDiv.setAttribute("class", reportClass)
    },

    contactUs () {
      this.showConcact = true
    },

    playDiscoveryVideo () {
      this.correctPlayState('play')
      const video = document.getElementById('discovery')
      video.play()
      video.addEventListener("pause", () => {
        this.correctPlayState('pause')
      })
      video.addEventListener("play", () => {
        this.correctPlayState('play')
      })
    },

    correctPlayState (type) {
      const discoveryText = document.getElementsByClassName('discovery-box')[0]
      const playBtn = document.getElementsByClassName('play-btn')[0]
      if (type === 'play') {
        discoveryText.style['z-index'] = 0
        playBtn.style.opacity = 0
        this.videoControl = true
      } else {
        discoveryText.style['z-index'] = 2
        playBtn.style.opacity = 1
      }
    },

    closeContactDialog () {
      this.showConcact = false
    },

    closeLoginDialog () {
      this.showLogin = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.home-page::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.home-page {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  width: 100vw;
  height: 100vh;
  background: #000;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: "PingFang SC", "Microsoft YaHei";
  box-sizing: border-box;
  user-select: text; 

  .home-header {
    width: 85vw;
    max-width: 1600px;
    margin: 1.5vw auto 4vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pdm-logo {
      width: 15vw;
      max-width: 200px;
    }

    .login-btn {
      height: 32px;
      line-height: 32px;
      padding: 0 14px;
      text-align: center;
      color: #fff;
      background: #FF8C36;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .main-text {
    width: 85vw;
    max-width: 1600px;
    margin: 0 auto;
  }

  .slogan-box {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    font-size: 10vw;
    color: #fff;
    font-family: 'NeoTech Black';

    .flex-box {
      display: flex;
      justify-content: space-between;
    }

    .two-text {
      width: 20%;
    }

    .four-text {
      width: 35%;
    }

    .five-text {
      width: 46%;
    }

    .six-text {
      width: 54%;
    }
    
    .normal-text {
      font-family: "Gilroy Bold";
    }

    .gradient-text {
      position: relative;
      height: 11vw;
      overflow: hidden;
      display: inline-block;
      font-weight: 600;
      .init-text {
        background: #000000;
        mix-blend-mode: multiply;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .change-text {
        opacity: 1;
        transition: all 0.25s linear 0s;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
    .gradient-text::before {
      background-image: linear-gradient(90deg, #FFC067 0%, #FF8749 14%, #FFC948 43%, #FF7D3D 64%, #FFA33A 81%, #FF8C36 96%);
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: solid 1px #000000;
      transform: scale(-1, -1);
    }
    .gradient-text::after {
      background-image: linear-gradient(90deg, #FFC067 0%, #FF8749 14%, #FFC948 43%, #FF7D3D 64%, #FFA33A 81%, #FF8C36 96%);
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: solid 1px #000000;
      animation: fade 1.2s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite alternate;
    }
    @keyframes fade {
      0% {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width:2100px) {
    .slogan-box {
      font-size: 11vw;
      .gradient-text {
        height: 13vw;
      }
    }
  }

  .icon-box {
    width: 85vw;
    margin: 1vw auto;
    display: flex;
    align-items: center;

    .music-pic {
      width: 3vw;
      height: 3vw;
    }

    .plus-icon {
      width: 1vw;
      height: 1vw;
      margin: 0 0.5vw; 
    }
  }

  .sub-text {
    width: 85vw;
    margin: 0 auto;
    color: #fff;
    margin: 2vw auto 6vw;
    font-size: 1.4vw;
    line-height: 2vw;
  }

  .tech-list {
    width: 85vw;
    max-width: 1600px;
    margin: 0 auto 6vw;
    display: flex;
    justify-content: space-between;

    .tech-item {
      flex: 1;
      background: #1E1E1F;
      margin-right: 1vw;
      height: 30vw;
      border-radius: 1.2vw;
      color: #fff;
      position: relative;
      overflow: hidden;

      img {
        width: 20vw;
        margin: 0 auto;
      } 

      .tech-text {
        position: relative;
        margin: 0 1.4vw;
        transition: all 0.75s ease 0s;

        .tech-no {
          font-size: 2.7vw;
          font-family: "Gilroy Bold";
          font-weight: 600;
          margin-top: 7vw;
        }

        .tech-name {
          margin: 1.2vw 0;
          font-size: 1.6vw;
          line-height: 2vw;
          height: 1.6vw;
        }

        .tech-desc {
          border-top: 1px solid rgba(255, 255, 255, 0.6);
          padding-top: 1.2vw;
          position: absolute;
          bottom: -9vw;
          opacity: 0;
          font-size: 0.9vw;
          line-height: 1.5vw;
          height: 4vw;
        }
      }
    }

    .tech-item:last-child {
      margin-right: 0;
    }

    .tech-item:hover {
      .tech-text {
        transform: translateY(-9vw);
        transition: all 0.75s ease 0s;
      }
      .tech-no {
        opacity: 0;
        transition: opacity 0.2s;
      }
      .tech-desc {
        opacity: 0.5;
      }
    }
  }

  .solution-box {
    font-size: 40px;
    color: #fff;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    text-align: left;

    .dsp-box, .distributors-box {
      width: 100vw;
      height: 100vh;
      text-align: center;
      border-radius: 50px 50px 0 0; 
      box-sizing: border-box;
    }

    .solution-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 85vw;
      max-width: 1600px;
      margin: 0 auto;
      height: 7vh;
      cursor: pointer;

      .solution-title {
        font-size: 2.4vw;
        font-family: "NeoTech Black";
        font-weight: 600;
        line-height: 2vw;
      }

      .more-btn {
        height: 2.6vw;
        line-height: 2.6vw;
        font-size: 1vw;
        border: 1px solid #FFFFFF;
        border-radius: 2vw;
        padding: 0 1.5vw;
        cursor: pointer;
      }
    }

    .dsp-content {
      background: #fff;
      width: 85vw;
      max-width: 1600px;
      margin: 0 auto;
      flex: 1;
      border-top-left-radius: 1vw;
      border-top-right-radius: 1vw;
      color: #000;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .current-scene {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4vw;
      text-align: left;

      .scene-title {
        width: 20%;
        font-size: 1.6vw;
      }

      .scene-desc {
        line-height: 1.6vw;
      }

      .per-day {
        width: 30%;
        font-size: 1.2vw;

        .num-60 {
          font-size: 5vw;
          font-family: 'Gilroy Bold';
          font-weight: 600;
          line-height: 5vw;
        }

        .unit-text {
          position: relative;
          left: 1vw;
          bottom: 0.2vw;
        }
      }
      .per-week {
        width: 30%;
        font-size: 1.2vw;
        
        .num-1000 {
          font-size: 3.5vw;
          font-family: 'Gilroy Bold';
          font-weight: 600;
          line-height: 5vw;
        }
      }
    }

    .with-pdm {
      flex: 1;
      border-top: 1px solid rgba(0,0,0,0.15);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4vw 4vw 2.5vw 4vw;
      text-align: left;

      .pdm-scene {
        width: 20%;
        .with-text {
          font-size: 1.6vw;
        }
        .pdm-text {
          font-size: 5.5vw;
          font-family: 'Gilroy Bold';
          font-weight: 600;
          margin-top: 2vw;
        }
      }
      
      .num-box {
        width: 30%;
        display: flex;
      }
      
      .star-icon {
        position: relative;
        width: 3vw;
        height: 3vw;
      }

      .left-star {
        align-self: flex-end;
        margin-right: 2vw;
        bottom: 0.5vw;
      }

      .right-star {
        align-self: flex-start;
        margin-left: 2vw;
        bottom: 2vw;
      }

      .num-0 {
        font-size: 12vw;
        font-family: 'Gilroy Bold';
        font-weight: 600;
        color: #FF9700;
        line-height: 10vw;
      }

      .pdm-desc {
        width: 30%;
        font-size: 4vw;
        font-family: 'Gilroy Bold';
        font-weight: 600;
      }
    }

    .solution-desc {
      width: 85vw;
      max-width: 1600px;
      margin: 1.5vh auto 1.5vh;
      text-align: left;
      font-size: 1.2vw;
      font-weight: 400;
      line-height: 1.8vw;
      opacity: 0.8;
    }

    .dsp-box {
      position: absolute;
      background: #F1924B;
      z-index: 5;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 3vh 0 11vh 0;
    }

    .distributors-box {
      padding: 3vh 0;
      width: 100vw;
      overflow: hidden;
      background: #E46B4F;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 10;
      transform: translateY(88vh);
      will-change: transform;
    }
  }

  .distributor-sub {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    width: 85vw;
    max-width: 1600px;
    margin: 0 auto;

    .next-btn {
      position: relative;
      margin-right: 4vw;
      opacity: 0.8;
      cursor: pointer;
      animation: 1.5s linear 0s infinite alternate move_btn;
    } 

    @keyframes move_btn {
      0% {
        left: 0;
      }
      50% {
        left: 0.7vw;
      }
      100% {
        left: 0;
      }
    }

    .is-last {
      transform: rotate(180deg);
    }
  }
  
  .pdm-report {
    height: 80%;
    width: 140vw;
    display: flex;

    img {
      height: 62vh;
      border-radius: 1vw; 
      margin-left: 14vw;
    }
  }

  .next-report {
    transform: translateX(0);
    transition: all 0.75s ease 0s;
  }

  .last-report {
    transform: translateX(-100vh);
    transition: all 0.75s ease 0s;
  }

  .high-light {
    background: #000;
    color: #fff;
    display: flex;
    width: 140vw;
    will-change: transform; 
    transform-style: preserve-3d;
    transform: translate3d(5vw, 0, 0); 

    .module-box {
      width: 58vw;
      background: rgb(30, 30, 30);
      border-radius: 10px;
      margin: 5vw;
      height: 28vw; 
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .mirex-box {
      padding: 0 3vw; 
    }

    .mirex-text {
      flex: 1;
      margin-left: 3vw;

      .module-desc {
        width: 30vw;
      }
    }

    .module-name {
      font-size: 4vw;
      font-weight: 600; 
      font-family: 'NeoTech Black';
    }

    .module-desc {
      font-size: 1vw;
      margin-top: 1.3vw;
      line-height: 1.8vw;
      opacity: 0.8;
    }

    .mirex-logo {
      width: 20vw;
      height: 20vw;
      border-radius: 10px;
      background: #fff;
    }

    .discovery-box {
      position: relative;
      top: 5vw;
      left: 6vw;
      z-index: 2;
    }

    .video-div {
      position: relative;
    }

    .video-box {
      width: 38vw;
      border-radius: 1vw;
      margin-right: 3vw;
    }

    .play-btn {
      position: absolute;
      width: 5vw;
      top: 6vw;
      right: 20vw;
      cursor: pointer;
    }
  }
  
  .gray-bg {
    background: #F7F7F7;
    width: 100vw;
    padding: 5vw 0;
    height: 63vw;
  }

  .gray-box {
    width: 85vw;
    max-width: 1600px;
    margin: 0 auto;
  }

  .mb-7vw {
    margin-bottom: 7vw;
  }

  .module-title {
    font-family: 'NeoTech Black';
    font-size: 3vw;
    font-weight: 600; 
    margin-bottom: 3vw;
  }

  .company-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5vw;

    img {
      width: 18vw;
      max-width: 600px;
    }
  }

  .team-brief {
    width: 60vw;
    opacity: 0.5;
    font-size: 1.2vw; 
    font-weight: 400;
    line-height: 2.4vw;
    margin: 1vw 0;
  }

  .contact-btn {
    width: 15vw;
    text-align: center;
    height: 4vw;;
    border-radius: 2vw;
    border: 1px solid #000000;
    font-size: 1.5vw;
    font-weight: 600;
    line-height: 4vw;
    margin-top: 2.5vw;
    cursor: pointer;
  }
  
  .home-footer {
    background: #fff;
    padding: 2.5vw 0;
    min-height: 8vw;
    width: 100vw;

    .footer-box {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 85vw;
      max-width: 1600px;
      margin: 0 auto;
    }

    .logo-img {
      width: 12vw; 
      margin-bottom: 2.7vw;
    }

    .copy-right {
      font-size: 1vw;
      opacity: 0.5;
    }

    .footer-link {
      width: 12vw;
      margin-left: 3vw;
    }
  }
}
</style>