<template>
  <el-dialog :visible.sync="dialogVisible" custom-class="login-dialog" @close="closeDialog">
    <div class="login-content">
      <div class="get-in-touch">
        <img src="../../assets/home/logo-all-white.png">
      </div>

      <div class="login-form">
        <img
          class="close-btn"
          src="../../assets/home/close.png"
          @click="closeDialog"
        >

        <div v-if="dialogType === 'login' && !isWoa" class="login-box">
          <p class="page-type">Welcome</p>

          <el-input v-model.trim="loginForm.email" placeholder="Email Address"></el-input>
          <el-input v-model.trim="loginForm.password" show-password placeholder="Password" @keyup.enter.native="toLogin"></el-input>
          <div class="btn-list">
            <el-button v-if="woaEmail !== 'XXX@tencent.com'" @click="isWoa = true" class="plain-btn">WOA登录</el-button>
            <el-button @click="toLogin">Login</el-button>
          </div>

          <p class="tac">Don't have an account yet? <span class="link-text" @click="dialogType = 'signup'">Sign Up</span></p>
        </div>

        
        <div v-if="dialogType === 'login' && isWoa" class="login-box">
          <p class="page-type">Welcome</p>

          <p>检测到账号:</p>
          <el-input v-model="woaEmail" disabled placeholder="Email Address"></el-input>
          
          <div class="btn-list">
            <el-button @click="isWoa = false" class="plain-btn">普通登录</el-button>
            <el-button @click="woaLogin">WOA登录</el-button>
          </div>

          <p class="tac">Don't have an account yet? <span class="link-text" @click="dialogType = 'signup'">Sign Up</span></p>
        </div>

        <div v-if="dialogType === 'signup'" class="sign-up-box">
          <p class="page-type">New Account</p>
          <el-input v-model="registForm.name" placeholder="Username"></el-input>
          <el-input v-model.trim="registForm.email" placeholder="Email Address"></el-input>
          <el-input v-model.trim="registForm.password" placeholder="Password"></el-input>
          <el-input v-model="registForm.code" placeholder="Invitation Code" @keyup.enter.native="toRegister"></el-input>
          <el-button @click="toRegister">Sign Up</el-button>
          <p class="tac link-text" @click="dialogType = 'login'">Return to login</p>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import http from "@/lib/http"

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      dialogVisible: false,
      dialogType: 'login',
      loginForm: {
        email: '',
        password: ''
      },
      registForm: {
        name: '',
        email: '',
        password: '',
        code: ''
      },
      url: '',
      isWoa: false,
      woaEmail: 'XXX@tencent.com',
      mailReg: /([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+/
    }
  },

  computed: {
    token: function () {
      return localStorage.getItem("token")
    }
  },

  watch: {
    visible (val) {
      this.dialogVisible = val
    }
  },

  mounted () {
    this.getWoa()
  },

  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    },

    getWoa() {
      if (!this.token) {
        this.url = window.location.origin
      }
      if (
        this.url == "https://pdm.woa.com" ||
        this.url == "http://pdm.woa.com" ||
        this.url == "https://test.pdm.woa.com" ||
        this.url == "http://test.pdm.woa.com"
      ) {
        this.isWoa = true
        http.get(this.url).then((res) => {
          if (res.status == 200) {
            this.woaEmail = res.headers.rtx + "@tencent.com"
          } else {
            this.$message({
              message: '获取账户信息错误，请刷新页面重试或联系jinzli',
              type: "error",
              offset: 80,
              duration: 1000
            })
          }
        });
      }
    },

    initUserInfo (res) {      
      localStorage.clear()
      localStorage.setItem("token", res.token)
      localStorage.setItem("username", res.name)
      localStorage.setItem("country", res.region || 'China')
      this.$store.commit("UPDATE_COUNTRY", { country: res.region || 'China' })
      localStorage.setItem("lang", res.language === 'en' ? 'en' : 'zh')
      this.$i18n.locale = localStorage.getItem("lang")
      localStorage.setItem("usertype", res.user_type)
      this.$store.commit("UPDATE_USER", { usertype: res.user_type })
      this.$store.commit("UPDATE_USER", { token: res.token, email: this.loginForm.email })
      setTimeout(() => {
        this.$router.push('/task-list')
      }, 100)
    },

    async woaLogin() {
      if (this.isWoa && (this.woaEmail !== 'XXX@tencent.com')) {
        try {
          const res = await this.$store.dispatch('login', {
            email: this.woaEmail
          })
          if (res.success) {
            this.$message({
              message: this.$t('h.account.loginSuccess'),
              type: "success"
            })
            this.initUserInfo(res)
          } else {
            this.$message({
              message: '获取账户信息错误，请刷新页面重试或联系jinzli',
              type: "error"
            })
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.$message({
          message: "请等待获取账户信息或刷新页面重试",
          type: "error"
        })
      }
    },

    async toRegister() {
      if (!this.registForm.name) {
        this.$message({
          message: this.$t('h.account.username'),
          type: "warning"
        })
      } else if (!this.registForm.email) {
        this.$message({
          message: this.$t('h.account.enterMail'),
          type: "warning"
        })
      } else if (!this.mailReg.test(this.registForm.email)) {
        this.$message({
          message: this.$t('h.account.enterMail'),
          type: "warning"
        })
      } else if (!this.registForm.password) {
        this.$message({
          message: this.$t('h.account.enterPsw'),
          type: "warning"
        })
      } else if (!this.registForm.code) {
        this.$message({
          message: this.$t('h.account.inviteCodeMsg'),
          type: "warning"
        })
      } else {
        try {
          const res = await this.$store.dispatch("register", this.registForm)
          if (res.success) {
            this.$message({
              message: "success",
              type: "success"
            })
            localStorage.setItem("token", res.token)
            localStorage.setItem("username", this.registForm.name)
            this.$store.commit("UPDATE_USER", { token: res.token })
            setTimeout(() => {
              this.$router.push('/task-list')
            }, 100)
          } else {
            this.$message({
              message: res.msg,
              type: "error"
            })
          }
        } catch (err) {
          console.log(err)
        }
      }
    },

    async toLogin() {
      const loginForm = this.loginForm
      if (!loginForm.email) {
        this.$message({
          message: this.$t('h.account.enterMail'),
          type: "warning"
        })
      } else if (!loginForm.password) {
        this.$message({
          message: this.$t('h.account.enterPsw'),
          type: "warning"
        })
      } else if (!this.mailReg.test(loginForm.email)) {
        this.$message({
          message: this.$t('h.account.mailFormat'),
          type: "warning"
        })
      } else {
        try {
          const res = await this.$store.dispatch("login", {
            email: loginForm.email.trim(),
            password: loginForm.password.trim()
          })
          if (res.success) {
            this.$message({
              message: this.$t('h.account.loginSuccess'),
              type: "success"
            })
            this.initUserInfo(res)
          } else {
            this.$message({
              message: res.msg,
              type: "error"
            })
          }
        } catch (err) {
          console.log(err)
        }
      }
    }
  }
}
</script>

<style lang="stylus">
.login-dialog {
  width: 600px;
  border-radius: 10px !important;

  .el-dialog__header {
    padding: 0;

    .el-dialog__headerbtn {
      display: none;  
    }
  }

  .el-dialog__body {
    padding: 0;
  }

  .login-content {
    width: 100%;
    height: 400px;
    display: flex;
  }

  .get-in-touch {
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px; 
    background: #FF8C36;
    width: 30%;
    color: #fff;
    padding: 50px 40px 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    img {
      width: 90%;
    }
  }

  .login-form {
    flex: 1;
    position: relative;
    padding: 40px 30px;
    display: flex;
    align-items: center;

    .close-btn {
      position: absolute;
      right: 0;
      top: 0;
      width: 25px;
      cursor: pointer;
    }

    .page-type {
      font-family: 'NeoTech Black';
      color: #000;
      font-size: 24px;
      margin-bottom: 20px;
    }

    .el-input__inner, .el-textarea__inner {
      border: none;
      background: #f5f5f5;
    }

    .el-button {
      background: #E46B4F;
      color: #fff;
    }

    .login-box, .sign-up-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }

    .login-box {
      height: 80%;
    }

    .sign-up-box {
      height: 100%;
    }

    .link-text {
      color: #E46B4F;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .btn-list {
    display: flex;
    justify-content: space-between;

    .el-button {
      flex: 1;
    }

    .plain-btn {
      border: 1px solid #E46B4F;
      background: #fff;
      color: #E46B4F;
    }
  }
}
</style>